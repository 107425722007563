:root {
  --primary-dark: #00000a;
  --highlight: #f7be25;
  --background-color: #fbfbf4;
  scrollbar-width: none;
  font-size: 16px;
  font-family: 'Inter';
}

::placeholder {
  font-family: 'Inter';
}

a {
  color: var(--primary-dark);
  font-style: normal;
  text-decoration: none;
}

h1 {
  font-size: large;
  font-weight: bold;
}

.title {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 10px;
}

.subtitle {
  font-style: italic;
  margin-bottom: 10px;
}

.App-header {
  display: flex;
  justify-content: space-between;
  color: var(--primary-dark);
  padding: 0 24px;
  top: 0;
  background-color: var(--background-color);
  position: fixed;
  width: 100%;
  transition: top 0.3s; /* Transition on scroll */
  z-index: 1;
}

.header__nav {
  display: flex;
  align-items: center;
}

.header__nav-link {
  padding: 0 6px;
}

.header__title {
  margin-right: 32px;
}

.header__image-text__subtitle {
  font-size: 22px;
  color: var(--background-color);
  font-style: italic;
}

.header__image-container {
  position: relative;
  text-align: center;
}

.header__image {
  object-fit: cover;
  filter: brightness(0.95);
  height: calc(100vh);
  width: 100%;
  opacity: 80%;
}

.header__image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header__image-text__title {
  font-size: 86px;
  color: var(--background-color);
  font-weight: 500;
}

.about {
  display: flex;
  align-items: center;
  margin: 24px;
}

.about__text {
  flex: 1;
  padding: 0 20px 0 0;
}

.about__text__body {
  line-height: 1.5;
}

.about__img {
  flex: 1;
  width: 100vw;
  max-height: 60vh;
  max-width: 60vh;
}

.info {
  margin: 12px;
  padding: 20px;
}

.image-block {
  display: flex;
  gap: 12px;
  margin: 24px;
}

.image-block img {
  max-width: 100%;
  height: auto;
  display: block;
}

.testimonial-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.testimonial-wrapper {
  display: flex;
  transition: transform 4s ease;
}

.testimonial {
  width: calc(33.33% - 64px); /* Adjust width to account for padding */
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px; /* Add padding to each testimonial */
  overflow: hidden; /* Hide overflow content */
}

.testimonial-text {
  margin-bottom: 10px;
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflow text */
}

.testimonial-author {
  font-style: italic;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  background-color: var(--highlight);
}

.contact-form {
  display: flex;
  margin: 24px;
}

.contact-form__text {
  flex: 1 1;
  margin: 0 0 0 12px;
}

.contact-form__form {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin: 0 0 0 12px;
}

.contact-form__input__message {
  max-width: 80%;
  min-width: 50%;
  height: 100px;
  padding: 10px;
}

.submit {
  width: 100px;
  height: 32px;
}

#contact-form {
  display: flex;
  flex-direction: column;
  gap: 4px;
  input {
    padding: 4px 8px;
    border-radius: 4px;
    border-width: thin;
  }
}

/* Media query for mobile devices */
@media only screen and (max-width: 1000px) {
  .header__image-text__title {
    font-size: 48px;
  }

  .header__image-text__subtitle {
    font-size: 24px;
  }
  .testimonial {
    width: calc(100% - 64px); /* Adjust width to account for padding */
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 32px; /* Add padding to each testimonial */
    overflow: hidden; /* Hide overflow content */
  }
  .about {
    flex-direction: column;
    gap: 24px;
  }

  .image-block {
    flex-direction: column;
  }
  .contact-form {
  flex-direction: column;
  }
}
